import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const alert = createSlice({
	name: 'alert',
	initialState: [],
	reducers: {
		SET_ALERT(state, action) {
			const id = uuidv4();
			const { msg, type } = action.payload;
			const newAlert = { id, msg, type };
			state.push(newAlert);
		},
		REMOVE_ALERT(state, action) {
			const id = action.payload;
			return state.filter((alert) => alert.id !== id);
		},
	},
});

export const alertActions = alert.actions;
export const SET_ALERT = alert.actions.SET_ALERT;
export const REMOVE_ALERT = alert.actions.REMOVE_ALERT;
export default alert;
